import React from "react";
import "./footer.scss";
import { FiLinkedin ,FiGithub, FiInstagram, FiFacebook } from "react-icons/fi";

const Footer = () => {
  return (
    <footer className="footer__container">
      <div className="footer__socials">
      <a href="https://www.linkedin.com/in/eric-lafontsee/" title="LinkedIn" target="_blank">
        <FiLinkedin />
      </a>
      <a href="https://github.com/ericlafontsee" title="Github" target="_blank">
        <FiGithub />
      </a>
      <a href="https://www.instagram.com/eric_lafontsee" title="Instagram" target="_blank">
        <FiInstagram />
      </a>
      <a href="https://www.facebook.com/eric.lafontsee" title="Facebook" target="_blank">
        <FiFacebook />
      </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Eric LaFontsee All Rights Reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
